<template>
    <v-card flat>
        <v-card-title>
            <div class="d-flex align-center justify-space-between w-100">
                <div class="d-flex flex-column">
                    <span class="secondary--text font-weight-bold">Organization users</span>
                    <span v-if="tab === tabValues.ORGANIZATION_MEMBERS_TAB && fetchingOrgUsers" class="caption">Fetching users data .....</span>
                    <span v-if="tab === tabValues.ORGANIZATION_INVITATIONS_TAB && fetchingOrgInvitations" class="caption">Fetching invitations data .....</span>
                </div>
            </div>
        </v-card-title>
        <v-card-text>
            <v-card class="mt-5 overflow-hidden">
                <v-tabs ref="tabs" color="secondary" v-model="tab">
                    <v-tab v-for="tab in tabs" :key="tab.tabName">
                        <v-icon class="mr-1" small>{{ tab.icon }}</v-icon>
                        {{ tab.tabName }}
                    </v-tab>
                    <v-spacer></v-spacer>
                    <v-tab v-if="isOrgManager">
                        <v-icon color="primary" class="mr-1" small>add</v-icon>
                        <span class="secondary--text font-weight-bold">invite</span>
                    </v-tab>
                </v-tabs>
            </v-card>

            <v-tabs-items class="mt-7" v-model="tab">
                <v-tab-item v-for="tab in tabs" :key="tab.tabName">
                    <component @goToInvite="switchTab($event.value, 'invite')" :is="tab.component"></component>
                </v-tab-item>
                <v-tab-item v-if="isOrgManager">
                    <component :is="'TheHomeOrganizationInvite'"></component>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
const TheHomeOrganizationUsers = () => import('../components/TheHomeOrganizationUsers')
const TheHomeOrganizationInvite = () => import('../components/TheHomeOrganizationInvite')
const TheHomeOrganizationInvitations = () => import('../components/TheHomeOrganizationInvitations')
export default {
    components: { TheHomeOrganizationUsers, TheHomeOrganizationInvite, TheHomeOrganizationInvitations },
    mixins: [enumsData],
    data() {
        return {
            tab: null,
            dataForInstanceToInviteTo: null,
            tabValues: {
                ORGANIZATION_MEMBERS_TAB: 0,
                ORGANIZATION_INVITATIONS_TAB: 1,
                ORGANIZATION_INVITE_TAB: 2
            }
        }
    },
    computed: {
        ...mapState('orgStore', ['fetchingOrgInvitations', 'fetchingOrgUsers']),
        ...mapGetters('orgStore', ['isOrgManager']),
        tabs() {
            if (this.isOrgManager) {
                return [
                    { tabName: 'Members', icon: 'mdi-account-group-outline', component: 'TheHomeOrganizationUsers' },
                    { tabName: 'Invitations', icon: 'mail', component: 'TheHomeOrganizationInvitations' }
                ]
            } else {
                return [{ tabName: 'Members', icon: 'mdi-account-group-outline', component: 'TheHomeOrganizationUsers' }]
            }
        }
    },
    methods: {
        switchTab(confirmAction, tab) {
            if (confirmAction && tab === 'invite') {
                this.tab = this.tabValues.ORGANIZATION_INVITE_TAB
            }
        }
    },
    mounted() {
        this.$store.dispatch('orgStore/fetchOrgUsers', this.$route.params.oid)
        this.$store.dispatch('orgStore/fetchOrgInvitations', this.$route.params.oid)
    },
    watch: {
        tab: function () {
            setTimeout(() => {
                this.$refs.tabs.onResize()
            }, 300)
        }
    }
}
</script>
